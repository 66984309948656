import React, { FC } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import AllIcon from 'src/images/icons/search/all.inline.svg'
import DocumentsIcon from 'src/images/icons/search/documents.inline.svg'
import LawsOfTheGameIcon from 'src/images/icons/search/laws-of-the-game.inline.svg'
import NewsIcon from 'src/images/icons/search/news.inline.svg'
import TheIfabIcon from 'src/images/icons/search/the-ifab.inline.svg'
import ChangesAndTrialsIcon from 'src/images/icons/search/changes-and-trials.inline.svg'
import lawsStore from 'src/components/laws/laws-store'
import { Link } from 'gatsby-plugin-react-i18next'

export enum ResultTypes {
  document = 'DOCUMENT',
  glossary = 'GLOSSARY',
  lawChange = 'LAW_CHANGE',
  lawSection = 'LAW_SECTION',
  news = 'NEWS',
  staticPage = 'STATIC_PAGE',
  theIfabBackground = 'THE_IFAB_BACKGROUND',
  organisation = 'THE_IFAB_ORGANIZATION',
  workProcess = 'THE_IFAB_WORK_PROCESS',
  trials = 'TRIALS',
}

const guidelinesChapters = [
  'other-advice',
  'body-language-communication-and-whistle',
  'positioning-movement-and-teamwork',
  'introduction',
]
export interface ISearchResult {
  title: string
  content: string
  objectId: string
  slug: string
  type: ResultTypes
  lawChapterSlug?: string
}

interface ISearchSingleResultProps {
  result: ISearchResult
}

const StyledResultContent = styled.p`
  max-height: 100px;
  em {
    ${tw`font-bold`}
  }
`

const GetIconByCategory = ({
  type,
  className,
}: {
  type: ResultTypes
  className: string
}) => {
  switch (type) {
    case ResultTypes.glossary:
    case ResultTypes.staticPage:
    case ResultTypes.lawSection: {
      return <LawsOfTheGameIcon className={className} />
    }
    case ResultTypes.lawChange:
    case ResultTypes.trials: {
      return <ChangesAndTrialsIcon className={className} />
    }
    case ResultTypes.theIfabBackground:
    case ResultTypes.workProcess:
    case ResultTypes.organisation: {
      return <TheIfabIcon className={className} />
    }
    case ResultTypes.document: {
      return <DocumentsIcon className={className} />
    }
    case ResultTypes.news: {
      return <NewsIcon className={className} />
    }

    default: {
      return <AllIcon className={className} />
    }
  }
}

const SearchSingleResult: FC<ISearchSingleResultProps> = ({ result }) => {
  const selectedSeason = lawsStore((state) => state.selectedSeason)
  const currentSeasonSlug = selectedSeason.slug

  const getResultLink = () => {
    switch (result.type) {
      case ResultTypes.lawSection: {
        if (result.lawChapterSlug === 'glossary') {
          return `/laws/${currentSeasonSlug}/${result.lawChapterSlug}/${result.slug}/`
        }
        if (guidelinesChapters.includes(result.lawChapterSlug)) {
          return `/laws/${currentSeasonSlug}/guidelines/${result.lawChapterSlug}/#${result.slug}`
        }
        return `/laws/${currentSeasonSlug}/${result.lawChapterSlug}/#${result.slug}`
      }
      case ResultTypes.glossary: {
        return `/laws/${currentSeasonSlug}/glossary/${result.slug}/`
      }
      case ResultTypes.trials: {
        return `/laws/${currentSeasonSlug}/${result.slug}/`
      }
      case ResultTypes.staticPage: {
        return `/laws/${currentSeasonSlug}/${result.slug}/`
      }
      case ResultTypes.lawChange: {
        return `/law-changes/${currentSeasonSlug}/`
      }
      case ResultTypes.theIfabBackground: {
        return '/background/'
      }
      case ResultTypes.workProcess: {
        return '/work-process/'
      }
      case ResultTypes.organisation: {
        return '/organisation/'
      }
      case ResultTypes.document: {
        return '/documents/'
      }
      case ResultTypes.news: {
        return `/news/${result.slug}/`
      }

      default: {
        return '/'
      }
    }
  }

  return (
    <div className="flex py-4 border-almostBlackBlue border-b">
      <div className="mx-2">
        <GetIconByCategory
          type={result.type}
          className="text-deepDarkBlue w-8"
        />
      </div>
      <div>
        <Link to={getResultLink()}>
          <h3
            className="font-bold font-lg"
            dangerouslySetInnerHTML={{ __html: result.title }}
          />
        </Link>
        <StyledResultContent
          className="overflow-hidden"
          dangerouslySetInnerHTML={{ __html: result.content }}
        />
      </div>
    </div>
  )
}

export default SearchSingleResult
